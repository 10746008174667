import React from 'react';
import logo from './LotinaLogo.jpg';
import service_icon from './lotina_service.png';
import './App.css';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';

function App() {
  return (
    <div className="App">
      <Container>
        <Row>
          <Image src={logo} alt="logo" fluid/>
        </Row>
        <Row style={{ color: '#138A9E' }}>
          <Col xs={12} md={6}>
            <h1 style={{ paddingBottom: '1em' }}>Company Profile</h1>
            <p className="text-justify">
            Lotina Co is a <b>Management Consultancy</b> established by experienced
            consultants with Sino-UK working experience from top tier management
            consultancies and technology firms.
            </p>
            <p className="text-justify">

            Lotina Co helps clients identify where their major issues and value
            drivers are, and work with them to assess how they can improve
            their business performance and optimise the value.
            </p>
            <p className="text-justify">
            Lotina co holds a <b>unique position</b> that possesses the wisdom of UK's established
            management system and strong technical capabilities from China that
            can solve future's problem and help business based in either UK or
            China to drive efficiency and operational excellence.
            </p>
          </Col>
          <Col xs={12} md={6}>
            <h1 style={{ paddingBottom: '1em' }}><Image src={service_icon} alt="service" style={{width:'1em'}}/> Services</h1>
            <h3>Operating Model Transformation</h3>
            <p className="text-justify">
              Strategically transform operating structure to gain competitive advantage.
            </p>
            <h3>Business Process Redesign</h3>
            <p className="text-justify">
              Optimise clients' core business processes to deliver more value to their customer.
            </p>
            <h3>Capability Building</h3>
            <p className="text-justify">
              Assess the right capabilities for the clients to have and support
              the capability development to sustain their business growth.
            </p>
          </Col>
        </Row>
        <Row style={{ color: '#138A9E' }}>
          <Col xs={12}><h1>Career</h1></Col>
          <Col xs={12}>
          With the fast growth of Lotina Co, we are currently looking for
          Calibre Graduates to join us who are interested in the following roles
          </Col>
          <Col xs={12}>
          <ul className="list-group-flush">
            <li>Business Consultant</li>
            <li>IT Consultant</li>
            <li>Developer</li>
            <li>Data Analyst</li>
          </ul>
          </Col>
          <Col xs={12}>
          For enquiry, please send a copy of the latest CV and the interested
          role to the email - <a href="mailto:info@lotina.co.uk"> info@lotina.co.uk</a>
          </Col>
        </Row>


        <footer style={{marginTop: '3em'}}>
          © Lotina Co 2018-2020
        </footer>
      </Container>
    </div>
  );
}

export default App;
